













































import {Component, Vue} from 'vue-property-decorator';
import TRDAService from "@/services/request/TRDAService";
import Application from "@/state/Application";
import TRDA_DTO from "@/dto/request/trda/TRDA_DTO";
import {processError} from "@/utils/ComponentUtils";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";

@Component({
  components: {EmbeddedFile}
})
export default class PublicTRDA extends Vue {

  trda: TRDA_DTO | null = null;

  mounted(): void {
    this.loadTRDA();
  }

  loadTRDA(): void {
    Application.startLoading();
    TRDAService.getByPublicId(this.id).then(
        ok => {
          this.trda = ok.data;
          Application.stopLoading();
        },
        err => {
          processError(err, this);
        }
    )
  }

  get id(): string {
    return this.$route.params.id;
  }

  approve(): void {
    this.save('APPROVE');
  }

  reject(): void {
    this.save('REJECT');
  }

  save(action: 'APPROVE' | 'REJECT'): void {
    Application.startLoading();
    TRDAService.savePublic(this.id, {action: action}).then(
        ok => {
          this.loadTRDA();
        },
        err => {
          processError(err, this);
        }
    )
  }

}
